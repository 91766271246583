import request from '@/util/request'

//获取课程列表
export function getCourseList(params) {
  return request({
    url: '/api/course/courseInfo/frontPage',
    method: 'GET',
    params
  })
}

//查询教师全部或者类目全部
export function getTeacherOrCategory(params) {
  return request({
    url: '/api/course/list',
    method: 'GET',
    params
  })
}

//查询所有一级类目
export function getCourseCategory(params) {
  return request({
    url: '/api/course/courseCategory/all',
    method: 'GET',
    params
  })
}

//查询所有一级类目
export function getCourseCategoryChildren(pid,params) {
  return request({
    url: `/api/course/courseCategory/children/${pid}`,
    method: 'GET',
    params
  })
}

//前台查看课程详情
export function getCourseDetail(params) {
  return request({
    url: '/api/course/courseInfo/getCourseInfoDetailById',
    method: 'GET',
    params
  })
}


//帮他人报名接口
export function applyForOther(data) {
  return request({
    url: 'api/course/courseApply/applyForOther',
    method: 'POST',
    data
  })
}

//帮自己报名接口
export function applyForSelf(data) {
  return request({
    url: '/api/course/courseApply/applyForSelf',
    method: 'POST',
    data
  })
}

//报名详情页
export function getApplyDetailById(params) {
  return request({
    url: '/api/course/courseApply/getCourseApplyById',
    method: 'GET',
    params
  })
}

//查询本人报名数据
// export function getUserInfo(params) {
//   return request({
//     url: '/api/course/courseApply/getUserInfo',
//     method: 'GET',
//     params
//   })
// }

//生成订单
export function getOrderNumber(data) {
  return request({
    url: '/api/course/courseApply/createOrder',
    method: 'POST',
    data
  })
}

//每个报名人的学习课程
export function getMyClass(params) {
  return request({
    url: '/api/course/courseApply/getCourseApplyByUserId',
    method: 'GET',
    params
  })
}

//获取学习进度
export function getMyClassDetail(applyOrderId,params) {
  return request({
    url: `/api/course/courseApply/getTask/${applyOrderId}`,
    method: 'GET',
    params
  })
}

//上报视频进度
export function pushCourseTask(data) {
  return request({
    url: '/api/course/courseTask/report_study_record',
    method: 'PUT',
    data
  })
}

//根据学生的id拿对应目录
export function getTaskByApplyId(applyId,params) {
  return request({
    url: `/api/course/courseApply/getTaskByApplyId/${applyId}`,
    method: 'GET',
    params
  })
}


//课程新接口

//课程类目下拉列表
export function courseCourseCategoryOps() {
  return request({
    url: `/api/course/courseCategory/ops`,
    method: 'GET',
  })
}
//后台/网站-课程信息列表（分页）
export function courseCourseInfoPage(data) {
  return request({
    url: `/api/course/courseInfo/page`
    +`?currentPage=${data.currentPage}`
    +`&pageSize=${data.pageSize}`
    +`&name=${data.name}`
    // +`&categoryId=${data.categoryId}`
    +`&collectFee=${data.collectFee}`
    +`&model=${data.model}`
    +`&signStartTime=${data.signStartTime}`
    +`&signEndTime=${data.signEndTime}`
    +`&startTime=${data.startTime}`
    +`&endTime=${data.endTime}`
    +`&status=${data.status}`
    +`&childCategoryId=${data.childCategoryId}`,
    method: 'GET',
    data
  })
}
//后台/网站-课程信息详情
export function courseCourseInfoInfo(id) {
  return request({
    url: `/api/course/courseInfo/info/${id}`,
    method: 'GET',
  })
}
// 获取登陆人信息
export const getUserInfo = (params) => {
  return request({
    url: '/api/system/Permission/Users/userInfo',
    method: 'get',
    params
  })
}

//课程确认报名
export const courseCourseApplyConfirmSign = (data) => {
  return request({
    url: '/api/course/courseApply/confirm/sign',
    method: 'post',
    data
  })
}






//获取可以考试的试卷列表
export const newCourseExamGetApplyDataList = (orderNumber) => {
  return request({
    url: '/api/course/exam/getApplyDataList/'+orderNumber,
    method: 'get',
  })
}
//根据考试examConfigId和applyId创建考试的订单
export const newCourseExamCreateExamOrder = (data) => {
  return request({
    url: '/api/course/exam/createExamOrder',
    method: 'get',
    data
  })
}
//根据applyId和courseId查询是否可以考试
export const newCourseExamExamInFo = (data) => {
  return request({
    url: '/api/course/exam/ExamInFo',
    method: 'get',
    data
  })
}
//获得剩余时间
export const newCourseExamGetPaperTimeByPaperId = (data) => {
  return request({
    url: '/api/course/exam/getPaperTimeByPaperId',
    method: 'get',
    data
  })
}
//根据试卷id获得题目
export const newCourseExamGetsubjectByPaperId = (data) => {
  return request({
    url: '/api/course/exam/getsubjectByPaperId',
    method: 'get',
    data
  })
}
//根据题目id获得题目详情
export const newCourseExamGetsubjectBySubjectId = (data) => {
  return request({
    url: '/api/course/exam/getsubjectBySubjectId',
    method: 'get',
    data
  })
}
//根据题目id获得题目详情(包括答案)
export const newCourseExamGetsubjectBySubjectIdWithAnswer = (data) => {
  return request({
    url: '/api/course/exam/getsubjectBySubjectIdWithAnswer',
    method: 'get',
    data
  })
}
//交卷接口
export const newCourseExamPaperFinish = (data) => {
  return request({
    url: '/api/course/exam/paperFinish',
    method: 'get',
    data
  })
}
//后台查询考试结果详情
export const newCourseExamResultDetail = (applyId) => {
  return request({
    url: '/api/course/exam/resultDetail/'+applyId,
    method: 'get',
  })
}
//后台分页查询考试结果
export const newCourseExamResultPage = (data) => {
  return request({
    url: '/api/course/exam/resultPage',
    method: 'get',
    data
  })
}
//根据题目id保存答案
export const newCourseExamSaveAnsewer = (data) => {
  return request({
    url: '/api/course/exam/saveAnsewer',
    method: 'post',
    data
  })
}
//根据examOrderId开始考试
export const newCourseExamStartExam = (data) => {
  return request({
    url: '/api/course/exam/startExam',
    method: 'post',
    data
  })
}
//报名的课程列表
export const newCourseExamApplyCourse = (data) => {
  return request({
    url: `/api/course/exam/apply/course?current=${data.current}&size=${data.size}&name=${data.name}`,
    method: 'get',
    data
  })
}