<template>
  <div class="wrapper-content">
    <div class="nav">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/course' }">教学服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/course-detail/${courseId}` }">
          {{ course.title }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>报名详情</el-breadcrumb-item>

      </el-breadcrumb>
    </div>

    <div class="detail">
      <div class="detail-image">
        <el-image v-if="course.coverImg" :src="loadUrl(course.coverImg)" fit="cover">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image
        >
      </div>
      <div class="detail-right">
        <h2>
          {{ course.title }}
        </h2>
        <div class="detail-right-textbg">
          <p>
            <span class="name">报名时间：</span>
            <span class="value"
            >{{ formatDateYMD(course.enrollStartTime) }} -
              {{ formatDateYMD(course.enrollEndTime) }}</span
            >
          </p>
          <p>
            <span class="name">授课地址：</span>
            <span class="value">{{ course.address }}</span>
          </p>
          <p>
            <span class="name">授课方式：</span>
            <span class="value">{{
                course.teachingMethod == 1 ? '线上视频' : course.teachingMethod == 2 ? '线上资料' : '线下'
              }}</span>
          </p>
        </div>
        <div class="detail-right-text">
          <p>
            <span class="name">培训时间：</span>
            <span class="value"
            >{{ formatDateYMD(course.startTime) }} -
              {{ formatDateYMD(course.endTime) }}</span
            >
          </p>
          <p>
            <span class="name">培训时长：</span>
            <span class="value">{{ course.courseLength }}</span>
          </p>
          <p>
            <span class="name">课程老师：</span>
            <span class="value">{{ teachers }}</span>
          </p>
          <p>
            <span class="name">付费类型：</span>
            <span class="price" v-if="course.feeType == 2">
              <span>¥{{ course.courseFee }}</span>
              <span class="discount" v-if="course.hasDiscounts==1">会员价：¥{{ discountPrice }}</span>
            </span>
            <span class="price" v-else>免费</span>
          </p>
        </div>


      </div>
    </div>

    <div class="steps-content-2">
      <div class="step-title">报名信息</div>
      <ul class="step-detail">
        <li style="padding-bottom: 12px;">
          <span class="title">订单类型：</span>
          <span class="value" v-if="orderInfo.orderType==0">普通订单</span>
          <span class="value" v-else-if="orderInfo.orderType==1 || orderInfo.orderType==2">非会员优惠订单</span>
          <span class="value" v-else-if="orderInfo.orderType==3 || orderInfo.orderType==4">会员优惠订单</span>
        </li>
        <li style="padding-bottom: 12px;">
          <span class="title">报名费用：</span>
          <span class="value">{{orderInfo.price}}</span>
        </li>
        <li>
          <span class="title">课程名称：</span>
          <span class="value">{{ course.title }}</span>
        </li>
        <li>
          <span class="title">创建时间：</span>
          <span class="value">{{formatDateTime(orderInfo.createTime)}}</span>
        </li>
      </ul>

      <div class="step-title">报名人员（共 {{ tableData2.length }} 位报名者）</div>
      <el-table
          :data="tableData2"
          key="key2"
          class="tab_apply"
          tooltip-effect="dark"
          border
      >

        <el-table-column
            type="selection"
            width="55"
            v-if="false"
        >
        </el-table-column>
        <el-table-column fixed type="index" label="序号" width="70">
        </el-table-column>
        <el-table-column prop="name" label="报名人员" width="140"/>
        <el-table-column prop="sex" label="性别" width="80">
          <template slot-scope="scope">{{
              scope.row.sex == 1 ? '男' : '女'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="idNumber" label="证件号码" width="200"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="180"></el-table-column>
        <el-table-column prop="address" label="联系地址"></el-table-column>
        <el-table-column prop="price" fixed="right" label="报名费用" width="180">
          <template slot-scope="scope">
            {{ course.courseFee }}元
          </template>
        </el-table-column>
      </el-table>


      <div class="apply-order">
        <h2>支付金额</h2>
        <p>
          <span class="name">报名费用：</span>
          <span class="value">￥{{ price }}</span>
        </p>
        <p>
          <span class="name" v-if="orderType==3 || orderType==4">会员优惠：</span>
          <span class="name" v-else>非会员优惠：</span>
          <span class="value">-￥{{ price - orderPrice }}</span>
        </p>
        <p class="danger">
          <span class="name">应付金额：</span>
          <span class="value" style="font-weight: bold">￥{{ orderPrice }}</span>
        </p>
      </div>

      <div class="step-title">支付信息</div>
      <ul class="step-detail">
        <li style="padding-bottom: 12px;">
          <span class="title">支付方式：</span>
          <span class="value">{{orderInfo.payChannel==='wx'? '微信支付':'支付宝支付'}}</span>
        </li>
        <li style="padding-bottom: 12px;">
          <span class="title">订单号：</span>
          <span class="value">{{orderInfo.orderNumber}}</span>
        </li>
        <li>
          <span class="title">付款时间：</span>
          <span class="value">{{ orderInfo.payTime }}</span>
        </li>
      </ul>
    </div>


  </div>
</template>

<script>
import {
  getApplyDetailById,
  getCourseDetail
} from '@/api/course'

export default {
  components: {},
  // props: {
  //   id: String
  // },
  data() {
    return {
      course: null,
      teachers: '',
      discountPrice: '',
      bigDialogVisible: false,
      tableData2: [],
      orderPrice: 0,
      price: 0,
      applyDetail:{},
      orderInfo:{},
      courseId:this.$route.query.courseId
    }
  },
  created() {
    this.init()
    // this.$store.dispatch("saveHeadActive", "/course");
  },
  methods: {
    init() {
      const self = this
      self.$store.commit('showLoading')
      getCourseDetail({ id: this.courseId }).then((res) => {
        if (res.code == 200) {
          self.course = res.data
          let teacherList = []
          self.course.teacherInfos.map((item) => {
            teacherList.push(item.name)
          })
          this.teachers = teacherList.join(',')
          this.course.discounts.map((item) => {
            if (item.discountType == 4) {
              this.discountPrice = item.discount
            } else if (item.discountType == 3) {
              this.discountPrice = this.course.courseFee * item.discount * 0.01
            }
          })
          getApplyDetailById({ id: this.course.applyPrderId }).then((res) => {
            if (res.code == 200) {
              this.applyDetail = res.data.courseInfo
              this.tableData2 = res.data.courseApplyList
              this.orderPrice = res.data.orderPrice
              this.price = res.data.price
              this.orderInfo=res.data
            }
          })
        } else {
          self.$message(res.msg)
        }
        self.$store.commit('hideLoading')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 26px;

  .detail-image {
    flex-shrink: 0;
    width: 460px;
    height: 338px;
    margin-right: 20px;

    .el-image {
      width: 100%;
      height: 100%;
    }
  }

  .detail-right {
    flex: 1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #555555;

    h2 {
      height: 22px;
      line-height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
  }

  .detail-right-text,
  .detail-right-textbg {
    padding: 4px 8px;
    line-height: 26px;
    margin-bottom: 10px;

    p {
      display: flex;
      align-items: top;
      margin-bottom: 5px;
    }

    .name {
      flex-shrink: 0;
      width: 70px;
      text-align: right;
      color: #999;
    }

    .value {
      flex: 1;
    }

    .red {
      color: #ff1d00;
    }

    .fz22 {
      font-size: 22px;
    }

    i.f12 {
      font-size: 12px;
    }

    .price {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 400;
      color: #ff1d00;
      margin-right: 6px;
      width: 158px;
      justify-content: space-between;
    }

    .discount {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 110px;
      height: 22px;
      background: #FF8001;
      border-radius: 11px;
      color: #fff;
      font-size: 14px;
    }
  }

  .detail-right-textbg {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 31px;
    background: url("../../assets/image/detail-textbg.png") center no-repeat;
  }
}


.steps-content-2, {
  border: 1px solid #e4e4e4;
  padding: 20px;
  background: #ffffff;
  width: 1200px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.step-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  margin-bottom: 12px;
}

.step-order-info {
  background: #f8f8f8;
  padding: 10px 20px;
  margin: 20px 0;
  line-height: 34px;
  font-size: 14px;
  color: #555;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
  }
}

.apply-order {
  margin-top: 30px;
  padding: 20px 0;
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
  color: #555555;
  line-height: 32px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 30px;
  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }

  .name {
    display: inline-block;
    width: 128px;
  }

  .value {
    display: inline-block;
  }
}

.nav {
  margin-top: 16px;

  .breadcrumb {
    font-size: 13px;
    color: #666666;
  }
}

.step-detail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 1200px;
  background: #f7f7f7;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 12px;
  margin-bottom: 24px;

  li {
    width: 50%;
  }
}

</style>

